.IndiviudalPictureImg{
    /* height: 100%; */
    width: auto;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    height: 100%; 
    /* object-fit: cover;
    width: 100%;
    height: 100%; */
    border: 15px solid;
    /* border-image: linear-gradient(to right, black 25%, white 25%, white 50%,black 50%, black 75%, black 75%) 5; */


}

.fullscreen{
    width: 25vw;
    height: 25vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.maxWidth{
    width: 100vw;
}
.ImageContentAndPicContainer{
    width: 100vw;
    height: 50vw;
    display: flex;
    flex-wrap: wrap;
}

.imgContainter{
    width: 50vw;
    height: 50vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.pictureContainterHolder{
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

}

.descriptionContainer{
    width: 50vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: space-evenly;
}
.picWriting{
    width: 100%;
}
.picTitle{
    font-size: 30px;
    font-weight: bold;
}
.picBody{

}
.picDate{

}



@media screen and (max-width: 750px) {
    .picTitle{
        font-size: 18px;
        font-weight: bold;
    }

}
