.FooterContainer{
    position: fixed;
    width: 100%;
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    padding-bottom: 10px;
}
.FooterText{
    display: inline-block;
    cursor: pointer;
    height:25px;
    padding-left: 30px;
    padding-right: 30px;
}

@media screen and (max-width: 600px) {
    .FooterText{
        font-size: 10px;
    }
}
.no-link{
    text-decoration: none;
    color: black;
  } 