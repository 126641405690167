/* .astroContainer{
    background-image: linear-gradient(65deg, rgb(235, 87, 200) 0%, #ff7969 100%);
    width: 250px;
    height: 150px;
    border-radius: 5px;
    text-align: center;
    margin: 30px;

} */

/* .titleContainer{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: space-around;
} */


.MainPage{
    height: 95vh;
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    text-align: center;

}

.MainPageBody{
    height: 75%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-around;
    /* height: 400px;
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: space-around; */
}

.MainTitleContainer{
    width: 100%;
}


.MainTitle{
    width: 100%;
    cursor: default;
    height:auto;
    /* color: #111;
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 100px;
    font-weight: bold;
    letter-spacing: -1px;
    line-height: 1;
    text-align: center; */
    font-family: times, Times New Roman, times-roman, georgia, serif;
	color: #111;
	margin: 0;
	padding: 0px 0px 6px 0px;
	font-size: 100px;
	line-height: 80px;
	letter-spacing: -2px;
	font-weight: bold;

}

.MainPageContent{
    width: 100%;
    /* margin-top:50px; */
    height: 50vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: space-around;
}

.MainPageContentContainer{
    height: 33%;
    width:100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    text-align: center;
}

.MainPageContentText{
    /* width: 200px; */
    cursor: pointer;
    font-family: Gill Sans, Verdana;
	font-size: 20px;
	line-height: 14px;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-weight: bold;
    height:25px;
    color:black;

}

a:link {
    text-decoration: none;
    text-decoration-color: black;
}
  
a:visited {
    text-decoration: none;
    text-decoration-color: black;
}
  
a:hover {
    text-decoration: none;
    text-decoration-color: black;
}
  
a:active {
    text-decoration: none;
    text-decoration-color: black;
}



.MainPageFooterContainer{
    width: 100vw;

}


.SetWidth{
    width:100%
}



@media screen and (max-width: 600px) {
    .MainTitle{
        font-size: 50px;
        line-height: 40px;
    }
    .MainPageContentText{
        font-size: 15px;
    }
}