*{
  margin:0; 
  padding:0;
  box-sizing: border-box;
  /* background-image: url(https://ttc.git.tmoses.co.uk/new/assets/bg/bg-76.svg); 
  background-repeat: no-repeat;
  background-size: auto;  */
  /* background-color: #2c343f; */
  font-family: 'Futura';
}


.canvas{

  background-image: url('./wallpaper.svg');
  /* background-image: url('./Components/Photos/BUS.jpeg'); */


  
  background-color: slategrey;
  height: 100vh;
  width: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

