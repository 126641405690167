.TopPicsMainTitle{
    /* width: 100%; */
    cursor: default;
    /* height:auto; */
    /* color: #111;
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 100px;
    font-weight: bold;
    letter-spacing: -1px;
    line-height: 1;
    text-align: center; */
    font-family: times, Times New Roman, times-roman, georgia, serif;
    color: black;
    /* margin: 0; */
    /* padding: 10vh 0px 0px 0px; */
    font-size: 50px;
    line-height: 80px;
    letter-spacing: -2px;
    font-weight: bold;
}
.maxWidth{
  width: 100vw;
}

.outerContainter {
  overflow: hidden;
  /* overflow-x: scroll; */
  position: relative;
}

.container {
  display: flex;
  padding: 55px;
  height: 50vh;
  /* position: relative; */
  /* bottom: 40vh; */
  min-width:1000px;
}


.item {
  flex: 0 0 19.7%;
  text-align: center;
  margin: 0 2px;
  transition: transform 300ms ease 100ms;
  width:30vh;
  border: 10px solid;
}


.container:hover .item {
  transform: translateX(-25%);
}

.item:hover ~ .item {
  transform: translateX(25%);
}

.item:hover {
  transform: scale(1.5) !important;
}


/* Pictures for the slider at bottom */

.pic1{
  background-image: url('../../../Photos/CoventGardenChristmas.jpeg');
  /* height: 100%; */
  width: auto;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  height: 100%;
}



.pic2{
  background-image: url('../../../Photos/LADY.jpeg');
  /* height: 100%; */
  width: auto;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  height: 50%;
}



.pic3{
  background-image: url('../../../Photos/No42.jpeg');
  /* height: 100%; */
  width: auto;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  height: 100%;
}



.pic4{
  background-image: url('../../../Photos/Bus2.jpeg');
  /* height: 100%; */
  width: auto;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  height: 50%;
}



.pic5{
  background-image: url('../../../Photos/Sohoho.jpeg');
  /* height: 100%; */
  width: auto;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  height: 100%;
}





@media screen and (max-width: 750px) {
  .TopPicsMainTitle{
      font-size: 32px;
      line-height: 32px;
      
  }
  /* .TopPicsMainTitle{
      font-size: 30px;
  } */


}