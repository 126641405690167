

.FullScreen{
    height: 100vh;
    width: 100vw;
    /* background-color: white; */
    margin-bottom: 5vh;

}

.GalleryTitleContainer{
    height: 25vh;
    width: 100%;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    padding-top: 5%;
}

.GalleryTitle{
    /* width: 100%; */
    cursor: default;
    /* height:auto; */
    /* color: #111;
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 100px;
    font-weight: bold;
    letter-spacing: -1px;
    line-height: 1;
    text-align: center; */
    font-family: times, Times New Roman, times-roman, georgia, serif;
	color: #111;
	/* margin: 0; */
	/* padding: 10vh 0px 0px 0px; */
	font-size: 80px;
	line-height: 80px;
	letter-spacing: -2px;
	font-weight: bold;
}
.galleryToggle{
    position: fixed;
    top: 1em;
    right: 1em;
    cursor: pointer;
}
.Links{
    cursor:pointer;
}


.GalleryContent{
    height: 75vh;
    width: 100vw;
    /* background-color: black; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    overflow-y: scroll;
    /* align-content: space-evenly; */
}
.GalleryLinks{
    width: 100vw;
    padding: 20px;
    cursor: default;
}





@media screen and (max-width: 750px) {
    .GalleryTitle{
        font-size: 50px;
        line-height: 50px;
        
    }
    /* .TopPicsMainTitle{
        font-size: 30px;
    } */
    .galleriesTitles{
        font-size: 10px;
    }

}

@media screen and (max-width: 275px) {
    .GalleryTitle{
        font-size: 30px;
        line-height: 50px;
        
    }

}